import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Backend from "../external/backend.js";
import TextInputComponent from "./TextInputComponent";

const LoginComponent = () => {
  const [customerId, setCustomerId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate(); // Use the useNavigate hook for navigation

  const handleCustomerIdChange = (event) => {
    setCustomerId(event.target.value);
    setError('');
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setError('');
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError('');
  };

  const handleLogin = async () => {
    if (customerId !== '' && username !== '' && password !== '') {
      // Authenticate credentials
      const body = {
        customerKey: customerId,
        username: username,
        password: password,
      };

      try {
        // contact the backend to authenticate the user
        const jwtToken = await Backend.login(body);

        // store the jwtToken in the browser's session storage
        sessionStorage.setItem("jwtToken", jwtToken);

        // allow the user to navigate to the dashboard
        setAuthenticated(true);
      }
      catch (error) {
        console.error("Error occurred during login:", error);
        setError("Unauthorised access. Please check your credentials.");
        setAuthenticated(false);
      }
    }
    else {
      // authentication details were not provided
      console.error("Authentication details were not provided.");
      setError("Please provide all the authentication details.");
      setAuthenticated(false);
    }
  };

  const handleKeyPress = (event) => {
    // Allow the user to press Enter to login instead of clicking the button
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="LoginComponent">
      <TextInputComponent label="Customer ID" value={customerId} isSecret={false} onChange={handleCustomerIdChange} />
      <TextInputComponent label="Username" value={username} isSecret={false} onChange={handleUsernameChange} />
      <TextInputComponent label="Password" value={password} isSecret={true} onChange={handlePasswordChange} onKeyDown={handleKeyPress}/>
      {error && <div className="ErrorMessage">{error}</div>}
      <button className="SubmitLoginButton" onClick={handleLogin}>Login</button>
      {authenticated && navigate("/dashboard")}
    </div>
  );
};

export default LoginComponent;
