import React from 'react';
import './TextInputComponent.css';

const TextInputComponent = ({ label, value, isSecret, onChange, onKeyDown }) => {
  return (
    <div className='TextInputComponent'>
      <label className='TextInputComponentLabel'>{label}</label>
      <input type={isSecret ? 'password' : 'text'} value={value} onChange={onChange} onKeyDown={onKeyDown} />
    </div>
  );
};

export default TextInputComponent;