import React, { useState, useEffect } from 'react';
import { Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { TablePagination, CircularProgress, FormControl, Select, MenuItem } from '@mui/material';
import Backend from '../external/backend';

const WalletsInfo = ({ blockchain, hdIndex, onWalletSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredWallets, setFilteredWallets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [walletsInfo, setWalletsInfo] = useState([]);
    const [transactionCustomers, setTransactionCustomers] = useState([]);

    useEffect(() => {
        const fetchData = async() => {
            setIsLoading(true);
            try{
                const wallets = await Backend.getWalletInfo(blockchain, hdIndex);
                const customers = await Backend.getTransactionCustomers(blockchain, hdIndex, true);

                wallets.map(wallet => {
                  wallet.data.tranCustomerName = customers.find(customer => customer.id === wallet.data.tranCustomerId)?.name ?? 'N/A';
                });
                setWalletsInfo(wallets);
                setTransactionCustomers(customers);
            } catch(error){
                console.error("Error fetching data:", error);
            } finally{
                setIsLoading(false);
            }
        };
        fetchData();
    }, [blockchain, hdIndex]);

    const filterWallets = (term) => {
        const filtered = walletsInfo.filter(wallet => {
            if(term === ''){
                return true;
            }
            return Object.values(wallet.data).some(value =>
                value.toString().toLowerCase().includes(term.toLowerCase())
            );
        });
        setFilteredWallets(filtered);
        setPage(0);
    };

    useEffect(() => {
        filterWallets(searchTerm);
    }, [searchTerm, walletsInfo]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleWalletClick = (wallet) => {
        onWalletSelect(wallet);
    };

    if(isLoading){
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
        </Box>
      );
    }

    return (
        <Box>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Search wallets..."
                value={searchTerm}
                onChange={handleSearch}
                sx={{
                    marginBottom: 2,
                    backgroundColor: 'black',
                    '& .MuiInputBase-input': {
                        color: 'white',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                    '& .MuiInputLabel-root': {
                        color: 'white',
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                }}
                InputProps={{
                    style: { color: 'white' },
                }}
            />
            <TableContainer component={Paper} sx={{ backgroundColor: 'black' }}>
                <Table sx={{ color: 'white' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Customer</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Address</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredWallets
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((wallet, index) => (
                                <TableRow
                                    key={index}
                                    hover
                                    onClick={() => handleWalletClick(wallet)}
                                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#333' } }}
                                >
                                    <TableCell sx={{ color: 'white' }}>{wallet.data.name ?? 'N/A'}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{wallet.data.tranCustomerName}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{wallet.data.address}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 30, 50]}
                component="div"
                count={filteredWallets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ color: 'white', backgroundColor: 'black' }}
            />
        </Box>
    );
};

export default WalletsInfo;
