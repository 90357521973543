import React from "react";
import { useParams } from 'react-router-dom';
import {QRCodeSVG} from "qrcode.react";
import toast, { Toaster } from 'react-hot-toast';
import './Payment.css';

/**
 * This page displays the details of the wallet that the payment should be made into.
 * It displays the amount that should be paid.
 * It also displays the QR code that the user can scan to make the payment.
 * The user can also copy the wallet address to the clipboard.
 * The user can also click on a button to mark the payment as complete.
 */
function Payment() {
  const { chain, address, amount } = useParams();

  const handleCopy = () => {
    navigator.clipboard.writeText(address);
    toast("Wallet address copied to clipboard");
  }

  return (
    <div className="Payment">
      <h1>{"Payment Details"}</h1>
      <p>{"Please make the payment to the following wallet address:"}</p>
      <div className="PaymentDetails">
        <span className="PaymentDetailLabel"><strong>Blockchain: </strong></span><span className="PaymentDetailValue">{chain}</span><br/>
        <span className="PaymentDetailLabel"><strong>Wallet address: </strong></span><span className="PaymentDetailValue">{address}</span><br/>
        <span className="PaymentDetailLabel"><strong>Amount: </strong></span>
        <span className="PaymentDetailValue">{amount} {chain === 'bitcoin' ? 'Satoshi' : 'Wei'}</span><br/>
      </div>
      {chain === 'bitcoin' && <QRCodeSVG className="PaymentQrCode" value={`bitcoin:${address}?amount=${amount}`} size={256} />}
      {chain === 'ethereum' && <QRCodeSVG className="PaymentQrCode" value={`ethereum:${address}?amount=${amount}`} size={256} />}
      <button className="PaymentCopyButton" onClick={handleCopy}>{"Copy wallet address"}</button>
      <Toaster />
    </div>
  );
}

export default Payment;