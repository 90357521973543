import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountInfo from './components/AccountInfo';
import WalletsInfo from './components/WalletsInfo';
import TransfersInfo from './components/TransfersInfo';
import TransferDetails from './components/TransferDetails';
import Backend from './external/backend';

function AccountExplorer() {
    const [blockchain, setBlockchain] = useState('');
    const [account, setAccount] = useState('');
    // const [walletSearch, setWalletSearch] = useState('');
    // const [transferSearch, setTransferSearch] = useState('');
    const [blockchainsAndAccounts, setBlockchainsAndAccounts] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [selectedTransfer, setSelectedTransfer] = useState(null);

    useEffect(() => {
        const getBlockchainsAndAccounts = async() => {
            setIsLoading(true);
            try{
                const chainsAndAccounts = {};
                const balanceData = await Backend.getBalance();
                for(const customerKey of Object.keys(balanceData)){
                    const customer = balanceData[customerKey];
                    for(const blockchain of Object.keys(customer)){
                        chainsAndAccounts[blockchain] = [];
                        for(const hdIndex of Object.keys(customer[blockchain])){
                            const accountInfo = {
                                id: hdIndex,
                                data: await Backend.getAccountInfo(blockchain, hdIndex)
                            };
                            accountInfo.data['numOfWallets'] = Object.keys(customer[blockchain][hdIndex].breakdown ?? {}).length;
                            chainsAndAccounts[blockchain].push(accountInfo);
                        }
                    }
                }
                setBlockchainsAndAccounts(chainsAndAccounts);
            } catch(error){
                console.error("Error fetching blockchains and accounts:", error);
            } finally{
                setIsLoading(false);
            }
        };
        getBlockchainsAndAccounts();
    }, []);

    const handleBlockchainChange = (event) => {
        setBlockchain(event.target.value);
        setAccount('');
        setSelectedWallet(null);
        setSelectedTransfer(null);
    };

    const handleAccountChange = (event) => {
        setAccount(event.target.value);
        setSelectedWallet(null);
        setSelectedTransfer(null);
    };

    // const handleWalletSearchChange = (event) => {
    //     setWalletSearch(event.target.value);
    // };

    const handleWalletSelectChange = (wallet) => {
        setSelectedWallet(wallet);
        setSelectedTransfer(null);
    };

    const handleTransferSelectChange = (transfer) => {
        console.log('transfer:', transfer);
        setSelectedTransfer(transfer);
    };

    if(isLoading){
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%', paddingTop: '20px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', alignItems: 'center' }}>
                <FormControl sx={{ minWidth: 200, marginRight: '10px' }}>
                    <InputLabel id="blockchain-label" sx={{ color: 'white', top: '-8px' }}>Blockchain</InputLabel>
                    <Select
                        value={blockchain}
                        onChange={handleBlockchainChange}
                        sx={{
                            backgroundColor: 'transparent',
                            color: 'white',
                            border: '1px solid white',
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                            '& .MuiInputLabel-root': { color: 'white', top: '-8px' }
                        }}
                    >
                        {Object.keys(blockchainsAndAccounts).map((chain) => (
                            <MenuItem key={chain} value={chain}>{chain}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 200, marginLeft: '10px' }}>
                    <InputLabel id="account-label" sx={{ color: 'white', top: '-8px' }}>Account</InputLabel>
                    <Select
                        value={account}
                        onChange={handleAccountChange}
                        sx={{
                            backgroundColor: 'transparent',
                            color: 'white',
                            border: '1px solid white',
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                            '& .MuiInputLabel-root': { color: 'white', top: '-8px' }
                        }}
                    >
                        {blockchainsAndAccounts[blockchain] && blockchainsAndAccounts[blockchain].map((acc) => (
                            <MenuItem key={`${blockchain}-${acc.id}-${acc.data.name}`} value={acc.id}>{acc.data.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Accordion defaultExpanded sx={{ backgroundColor: 'gray', color: 'white', borderRadius: '4px', marginBottom: '8px' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                  <Typography>Account Information</Typography>
              </AccordionSummary>

              {blockchain && account && (
                  <AccordionDetails>
                      <AccountInfo blockchain={blockchain} account={blockchainsAndAccounts[blockchain].find(acc => {
                          return acc.id === account;
                      })} />
                  </AccordionDetails>
              )}
            </Accordion>

            <Accordion defaultExpanded sx={{ backgroundColor: 'gray', color: 'white', borderRadius: '4px', marginBottom: '8px' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Wallet Information</Typography>
              </AccordionSummary>
              {blockchain && account && (
                <AccordionDetails>
                  <WalletsInfo blockchain={blockchain} hdIndex={account} onWalletSelect={handleWalletSelectChange} />
                </AccordionDetails>
              )}
            </Accordion>

            <Accordion defaultExpanded sx={{ backgroundColor: 'gray', color: 'white', borderRadius: '4px', marginBottom: '8px' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Transfer Information</Typography>
              </AccordionSummary>
              {blockchain && account && selectedWallet && (
                <AccordionDetails>
                  <TransfersInfo selectedWallet={selectedWallet} onTransferSelectChange={handleTransferSelectChange} />
              </AccordionDetails>
              )}
            </Accordion>

            <Accordion defaultExpanded sx={{ backgroundColor: 'gray', color: 'white', borderRadius: '4px', marginBottom: '8px' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Transfer Details</Typography>
              </AccordionSummary>
              {blockchain && account && selectedWallet && selectedTransfer && (
                <AccordionDetails>
                  <TransferDetails selectedWallet={selectedWallet} selectedTransfer={selectedTransfer} />
              </AccordionDetails>
              )}
            </Accordion>
        </Box>
    );
}

export default AccountExplorer;
