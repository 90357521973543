import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';

const TransfersInfo = ({ selectedWallet, onTransferSelectChange }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [allTransfers, setAllTransfers] = useState([]);
    const [filteredTransfers, setFilteredTransfers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        try{
            const transformTransfer = (blockchain, direction, transfer) => {
              if(blockchain === 'bitcoin'){
                return {
                  timestamp: new Date(transfer.timestamp * 1000).toLocaleString('en-GB', { timeZone: 'Europe/London' }),
                  direction: direction,
                  value: transfer.inAmount + transfer.outAmount,
                  currency: 'BTC',
                  transferId: transfer.txid,
                };
              }
              else if(blockchain === 'ethereum'){
                return {
                  timestamp: new Date(transfer.timestamp * 1000).toLocaleString('en-GB', { timeZone: 'Europe/London' }),
                  direction: direction,
                  value: transfer.value,
                  currency: transfer.token,
                  transferId: transfer.txid,
                }
              }
              else{
                return {};
              }
            }

            const allTransfers = [];
            if(selectedWallet.data.blockchain === 'bitcoin'){
              for(const transfer of selectedWallet.transactions?.incoming || []){
                allTransfers.push(transformTransfer(selectedWallet.data.blockchain, "incoming", transfer));
              }
              for(const transfer of selectedWallet.transactions?.outgoing || []){
                allTransfers.push(transformTransfer(selectedWallet.data.blockchain, "outgoing", transfer));
              }
            }
            else if(selectedWallet.data.blockchain === 'ethereum'){
              for(const token of Object.keys(selectedWallet.transactions?.incoming || [])){
                for(const transfer of selectedWallet.transactions.incoming[token]){
                  allTransfers.push(transformTransfer(selectedWallet.data.blockchain, "incoming", transfer));
                }
              }
              for(const token of Object.keys(selectedWallet.transactions?.outgoing || [])){
                for(const transfer of selectedWallet.transactions.outgoing[token]){
                  allTransfers.push(transformTransfer(selectedWallet.data.blockchain, "outgoing", transfer));
                }
              }
            }

            // sort allTransfers by timestamp
            allTransfers.sort((a, b) => {
                const dateA = new Date(a.timestamp.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
                const dateB = new Date(b.timestamp.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
                return dateB - dateA;
            });
            setAllTransfers(allTransfers);
            setPage(0);
        }
        catch(error){
            console.error("Error occurred while getting transfers information:", error);
        }
        finally{
            setIsLoading(false);
        }
    }, [selectedWallet]);

    useEffect(() => {
        if(searchTerm === ''){
            setFilteredTransfers(allTransfers);
        }
        else{
            const filtered = allTransfers.filter(transfer =>
                Object.values(transfer).some(value =>
                    value.toString().toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setFilteredTransfers([...filtered]);
        }
        setPage(0);
    }, [searchTerm, allTransfers]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(isLoading){
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Search transfers..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                    marginBottom: '1rem',
                    backgroundColor: 'black',
                    '& .MuiInputBase-input': {
                        color: 'white',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                    '& .MuiInputLabel-root': {
                        color: 'white',
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                }}
                InputProps={{
                    style: { color: 'white' },
                }}
            />
            <TableContainer component={Paper} sx={{ backgroundColor: 'black' }}>
                <Table sx={{ color: 'white' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Timestamp</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Direction</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Value</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Currency</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Transfer ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTransfers
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((transfer, index) => (
                                <TableRow
                                    key={index}
                                    hover
                                    onClick={() => onTransferSelectChange(transfer)}
                                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#333' } }}
                                >
                                    <TableCell sx={{ color: 'white' }}>{transfer.timestamp}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{transfer.direction}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{transfer.value}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{transfer.currency}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{transfer.transferId}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 30, 50]}
                component="div"
                count={filteredTransfers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ color: 'white', backgroundColor: 'black' }}
            />
        </div>
    );
};

export default TransfersInfo;
