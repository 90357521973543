import React from 'react';
import { Box, Typography, Grid, Card, CardContent, List, ListItem } from '@mui/material';

const AccountInfo = ({ blockchain, account }) => {
  const name = account?.data?.name || 'N/A';
  const numWallets = account?.data?.numOfWallets || 0;
  const numCustomers = account?.data?.transactionCustomers?.length || 0;
  const acceptedTokens = account?.data?.settings?.supportedTokens || [];

  const renderInfoCard = (title, value) => (
    <Grid item xs={12} sm={6} md={3}>
      <Card sx={{ height: '100%', backgroundColor: '#2c2c2c', color: 'white' }}>
        <CardContent>
          <Typography variant="subtitle1" component="div">{title}</Typography>
          <Typography variant="body1">{value}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
        {renderInfoCard("Name", name)}
        {renderInfoCard("Blockchain", blockchain)}
        {renderInfoCard("Num of Wallets", numWallets)}
        {renderInfoCard("Num of Customers", numCustomers)}
      </Grid>
      
      {blockchain === 'ethereum' && (
        <Card sx={{ backgroundColor: '#2c2c2c', color: 'white' }}>
          <CardContent>
          <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>Accepted Tokens:</Typography>
          <List sx={{ padding: 0 }}>
            {acceptedTokens.map((token, index) => (
              <ListItem key={index} sx={{ padding: '2px 0' }}>
                <Typography variant="body1">{`${index + 1}. ${token}`}</Typography>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
      )}
    </Box>
  );
};

export default AccountInfo;
