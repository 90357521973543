import React, { useState, useEffect } from 'react';
import { Box, Grid, CircularProgress } from '@mui/material';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import Backend from '../external/backend';
const BigNumber = require('bignumber.js');

const NewBalance = ({ selectedOption, selectedToken }) => {
  const [allBalances, setAllBalances] = useState({});
  const [specificBalance, setSpecificBalance] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBalance = async() => {
      setIsLoading(true);
      try {
        if(Object.keys(allBalances).length === 0) {
          const fetchedAllBalances = await calculateAllBalances();
          // console.log("fetchedAllBalances:", fetchedAllBalances);
          setAllBalances(fetchedAllBalances);
          const calculatedSpecificBalance = calculateSpecificBalance(fetchedAllBalances, selectedOption, selectedToken);
          // console.log("calculatedSpecificBalance:", calculatedSpecificBalance);
          setSpecificBalance(calculatedSpecificBalance);
        }
        else{
          const calculatedSpecificBalance = calculateSpecificBalance(allBalances, selectedOption, selectedToken);
          setSpecificBalance(calculatedSpecificBalance);
        }
      } catch (error) {
        console.error("Error fetching balance:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBalance();
  }, [selectedOption, selectedToken]);

  const calculateSpecificBalance = (allBalances, option, token) => {
    // console.log("allBalances:", allBalances);
    // console.log("option:", option);
    // console.log("token:", token);
    if(option === 'Bitcoin'){
      return {
        confirmed: allBalances.confirmed.bitcoin.credited,
        incoming: allBalances.unconfirmed.bitcoin.incoming,
        outgoing: allBalances.unconfirmed.bitcoin.outgoing,
      }
    }
    else if(option === 'Ethereum'){
      if(token === 'All Tokens'){
        return {
          confirmed: allBalances.confirmed.ethereum.total.credited,
          incoming: allBalances.unconfirmed.ethereum.total.incoming,
          outgoing: allBalances.unconfirmed.ethereum.total.outgoing,
        }
      }
      else {
        return {
          confirmed: allBalances.confirmed.ethereum[token].credited,
          incoming: allBalances.unconfirmed.ethereum[token].incoming,
          outgoing: allBalances.unconfirmed.ethereum[token].outgoing,
        }
      }
    }
    else if(option === 'All Blockchains'){
      return {
        confirmed: {
          crypto: undefined,
          usd: allBalances.confirmed.ethereum.total.credited.usd.plus(allBalances.confirmed.bitcoin.credited.usd),
          eur: allBalances.confirmed.ethereum.total.credited.eur.plus(allBalances.confirmed.bitcoin.credited.eur)
        },
        unconfirmed: {
          incoming: {
            crypto: undefined,
            usd: allBalances.unconfirmed.ethereum.total.incoming.usd.plus(allBalances.unconfirmed.bitcoin.incoming.usd),
            eur: allBalances.unconfirmed.ethereum.total.incoming.eur.plus(allBalances.unconfirmed.bitcoin.incoming.eur)
          },
          outgoing: {
            crypto: undefined,
            usd: allBalances.unconfirmed.ethereum.total.outgoing.usd.plus(allBalances.unconfirmed.bitcoin.outgoing.usd),
            eur: allBalances.unconfirmed.ethereum.total.outgoing.eur.plus(allBalances.unconfirmed.bitcoin.outgoing.eur)
          }
        }
      };
    }
  };

  const calculateAllBalances = async() => {
    let btcToUsdRate = 0;
    let btcToEurRate = 0;
    let ethToUsdRate = 0;
    let ethToEurRate = 0;
    let balance = {};

    const [btcUsd, btcEur, ethUsd, ethEur, balanceData] = await Promise.all([
      Backend.getExchangeRate('btc', 'usd'),
      Backend.getExchangeRate('btc', 'eur'),
      Backend.getExchangeRate('eth', 'usd'),
      Backend.getExchangeRate('eth', 'eur'),
      Backend.getBalance()
    ]);

    btcToUsdRate = new BigNumber(btcUsd.rate);
    btcToEurRate = new BigNumber(btcEur.rate);
    ethToUsdRate = new BigNumber(ethUsd.rate);
    ethToEurRate = new BigNumber(ethEur.rate);
    balance = balanceData;

    // console.log('ethToUsdRate:', ethToUsdRate)
    // console.log("balance:", balance);


    const result = {
      bitcoin: {
        credited: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
        incoming: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
        outgoing: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
      },
      ethereum: {
        total: {
          credited: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
          incoming: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
          outgoing: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
        },
        ETH: {
          credited: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
          incoming: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
          outgoing: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
        },
        USDT: {
          credited: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
          incoming: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
          outgoing: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
        },
        USDC: {
          credited: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
          incoming: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
          outgoing: {crypto: new BigNumber(0), usd: new BigNumber(0), eur: new BigNumber(0)},
        }
      }
    };

    for(const customerKey of Object.keys(balance)){
      // console.log("customerKey:", customerKey)
      const customer = balance[customerKey];
      for(const blockchain of Object.keys(customer)){
        // console.log("blockchain:", blockchain)
        for(const hdIndex of Object.keys(customer[blockchain])){
          // console.log("hdIndex:", hdIndex)
          const total = customer[blockchain][hdIndex].total;
          // console.log("total:", total)
          if(blockchain === 'bitcoin'){
            result.bitcoin.credited.crypto = result.bitcoin.credited.crypto.plus(new BigNumber(total.balance));
            result.bitcoin.credited.usd = result.bitcoin.credited.usd.plus(new BigNumber(total.balance).times(btcToUsdRate));
            result.bitcoin.credited.eur = result.bitcoin.credited.eur.plus(new BigNumber(total.balance).times(btcToEurRate));

            result.bitcoin.incoming.crypto = result.bitcoin.incoming.crypto.plus(new BigNumber(total.provChangeIn));
            result.bitcoin.incoming.usd = result.bitcoin.incoming.usd.plus(new BigNumber(total.provChangeIn).times(btcToUsdRate));
            result.bitcoin.incoming.eur = result.bitcoin.incoming.eur.plus(new BigNumber(total.provChangeIn).times(btcToEurRate));

            result.bitcoin.outgoing.crypto = result.bitcoin.outgoing.crypto.plus(new BigNumber(total.provChangeOut));
            result.bitcoin.outgoing.usd = result.bitcoin.outgoing.usd.plus(new BigNumber(total.provChangeOut).times(btcToUsdRate));
            result.bitcoin.outgoing.eur = result.bitcoin.outgoing.eur.plus(new BigNumber(total.provChangeOut).times(btcToEurRate));
          }
          else if(blockchain === 'ethereum'){
            result.ethereum.ETH.credited.crypto = result.ethereum.ETH.credited.crypto.plus(new BigNumber(total.balance.ETH));
            result.ethereum.ETH.credited.usd = result.ethereum.ETH.credited.usd.plus(new BigNumber(total.balance.ETH).times(ethToUsdRate));
            result.ethereum.ETH.credited.eur = result.ethereum.ETH.credited.eur.plus(new BigNumber(total.balance.ETH).times(ethToEurRate));

            result.ethereum.ETH.incoming.crypto = result.ethereum.ETH.incoming.crypto.plus(new BigNumber(total.provChangeIn.ETH));
            result.ethereum.ETH.incoming.usd = result.ethereum.ETH.incoming.usd.plus(new BigNumber(total.provChangeIn.ETH).times(ethToUsdRate));
            result.ethereum.ETH.incoming.eur = result.ethereum.ETH.incoming.eur.plus(new BigNumber(total.provChangeIn.ETH).times(ethToEurRate));

            result.ethereum.ETH.outgoing.crypto = result.ethereum.ETH.outgoing.crypto.plus(new BigNumber(total.provChangeOut.ETH));
            result.ethereum.ETH.outgoing.usd = result.ethereum.ETH.outgoing.usd.plus(new BigNumber(total.provChangeOut.ETH).times(ethToUsdRate));
            result.ethereum.ETH.outgoing.eur = result.ethereum.ETH.outgoing.eur.plus(new BigNumber(total.provChangeOut.ETH).times(ethToEurRate));

            result.ethereum.USDC.credited.crypto = result.ethereum.USDC.credited.crypto.plus(new BigNumber(total.balance.USDC));
            result.ethereum.USDC.credited.usd = result.ethereum.USDC.credited.usd.plus(new BigNumber(total.balance.USDC));
            result.ethereum.USDC.credited.eur = result.ethereum.USDC.credited.crypto.dividedBy(ethToUsdRate).times(ethToEurRate);

            result.ethereum.USDC.incoming.crypto = result.ethereum.USDC.incoming.crypto.plus(new BigNumber(total.provChangeIn.USDC));
            result.ethereum.USDC.incoming.usd = result.ethereum.USDC.incoming.usd.plus(new BigNumber(total.provChangeIn.USDC));
            result.ethereum.USDC.incoming.eur = result.ethereum.USDC.incoming.crypto.dividedBy(ethToUsdRate).times(ethToEurRate);

            result.ethereum.USDC.outgoing.crypto = result.ethereum.USDC.outgoing.crypto.plus(new BigNumber(total.provChangeOut.USDC));
            result.ethereum.USDC.outgoing.usd = result.ethereum.USDC.outgoing.usd.plus(new BigNumber(total.provChangeOut.USDC));
            result.ethereum.USDC.outgoing.eur = result.ethereum.USDC.outgoing.crypto.dividedBy(ethToUsdRate).times(ethToEurRate);

            result.ethereum.USDT.credited.crypto = result.ethereum.USDT.credited.crypto.plus(new BigNumber(total.balance.USDT));
            result.ethereum.USDT.credited.usd = result.ethereum.USDT.credited.usd.plus(new BigNumber(total.balance.USDT));
            result.ethereum.USDT.credited.eur = result.ethereum.USDT.credited.crypto.dividedBy(ethToUsdRate).times(ethToEurRate);

            result.ethereum.USDT.incoming.crypto = result.ethereum.USDT.incoming.crypto.plus(new BigNumber(total.provChangeIn.USDT));
            result.ethereum.USDT.incoming.usd = result.ethereum.USDT.incoming.usd.plus(new BigNumber(total.provChangeIn.USDT));
            result.ethereum.USDT.incoming.eur = result.ethereum.USDT.incoming.crypto.dividedBy(ethToUsdRate).times(ethToEurRate);

            result.ethereum.USDT.outgoing.crypto = result.ethereum.USDT.outgoing.crypto.plus(new BigNumber(total.provChangeOut.USDT));
            result.ethereum.USDT.outgoing.usd = result.ethereum.USDT.outgoing.usd.plus(new BigNumber(total.provChangeOut.USDT));
            result.ethereum.USDT.outgoing.eur = result.ethereum.USDT.outgoing.crypto.dividedBy(ethToUsdRate).times(ethToEurRate);

            result.ethereum.total.credited.crypto = result.ethereum.total.credited.crypto.plus(new BigNumber(total.balance.ETH));
            result.ethereum.total.credited.crypto = result.ethereum.total.credited.crypto.plus(new BigNumber(total.balance.USDC).dividedBy(ethToUsdRate));
            result.ethereum.total.credited.crypto = result.ethereum.total.credited.crypto.plus(new BigNumber(total.balance.USDT).dividedBy(ethToUsdRate));
            
            result.ethereum.total.credited.usd = result.ethereum.total.credited.usd.plus(result.ethereum.total.credited.crypto.times(ethToUsdRate));
            result.ethereum.total.credited.eur = result.ethereum.total.credited.eur.plus(result.ethereum.total.credited.crypto.times(ethToEurRate));

            result.ethereum.total.incoming.crypto = result.ethereum.total.incoming.crypto.plus(new BigNumber(total.provChangeIn.ETH));
            result.ethereum.total.incoming.crypto = result.ethereum.total.incoming.crypto.plus(new BigNumber(total.provChangeIn.USDC).dividedBy(ethToUsdRate));
            result.ethereum.total.incoming.crypto = result.ethereum.total.incoming.crypto.plus(new BigNumber(total.provChangeIn.USDT).dividedBy(ethToUsdRate));

            result.ethereum.total.incoming.usd = result.ethereum.total.incoming.usd.plus(result.ethereum.total.incoming.crypto.times(ethToUsdRate));
            result.ethereum.total.incoming.eur = result.ethereum.total.incoming.eur.plus(result.ethereum.total.incoming.crypto.times(ethToEurRate));

            result.ethereum.total.outgoing.crypto = result.ethereum.total.outgoing.crypto.plus(new BigNumber(total.provChangeOut.ETH));
            result.ethereum.total.outgoing.crypto = result.ethereum.total.outgoing.crypto.plus(new BigNumber(total.provChangeOut.USDC).dividedBy(ethToUsdRate));
            result.ethereum.total.outgoing.crypto = result.ethereum.total.outgoing.crypto.plus(new BigNumber(total.provChangeOut.USDT).dividedBy(ethToUsdRate));

            result.ethereum.total.outgoing.usd = result.ethereum.total.outgoing.usd.plus(result.ethereum.total.outgoing.crypto.times(ethToUsdRate));
            result.ethereum.total.outgoing.eur = result.ethereum.total.outgoing.eur.plus(result.ethereum.total.outgoing.crypto.times(ethToEurRate));
          }
        }
      }
    }

    return {
      confirmed: {
        bitcoin: {
          credited: { crypto: result.bitcoin.credited.crypto, usd: result.bitcoin.credited.usd, eur: result.bitcoin.credited.eur },
        },
        ethereum: {
          total: {
            credited: { crypto: result.ethereum.total.credited.crypto, usd: result.ethereum.total.credited.usd, eur: result.ethereum.total.credited.eur },
          },
          ETH: {
            credited: { crypto: result.ethereum.ETH.credited.crypto, usd: result.ethereum.ETH.credited.usd, eur: result.ethereum.ETH.credited.eur },
          },
          USDC: {
            credited: { crypto: result.ethereum.USDC.credited.crypto, usd: result.ethereum.USDC.credited.usd, eur: result.ethereum.USDC.credited.eur },
          },
          USDT: {
            credited: { crypto: result.ethereum.USDT.credited.crypto, usd: result.ethereum.USDT.credited.usd, eur: result.ethereum.USDT.credited.eur },
          },
        },
      },
      unconfirmed: {
        bitcoin: {
          incoming: { crypto: result.bitcoin.incoming.crypto, usd: result.bitcoin.incoming.usd, eur: result.bitcoin.incoming.eur },
          outgoing: { crypto: result.bitcoin.outgoing.crypto, usd: result.bitcoin.outgoing.usd, eur: result.bitcoin.outgoing.eur },
        },
        ethereum: {
          total: {
            incoming: { crypto: result.ethereum.total.incoming.crypto, usd: result.ethereum.total.incoming.usd, eur: result.ethereum.total.incoming.eur },
            outgoing: { crypto: result.ethereum.total.outgoing.crypto, usd: result.ethereum.total.outgoing.usd, eur: result.ethereum.total.outgoing.eur },
          },
          ETH: {
            incoming: { crypto: result.ethereum.ETH.incoming.crypto, usd: result.ethereum.ETH.incoming.usd, eur: result.ethereum.ETH.incoming.eur },
            outgoing: { crypto: result.ethereum.ETH.outgoing.crypto, usd: result.ethereum.ETH.outgoing.usd, eur: result.ethereum.ETH.outgoing.eur },
          },
          USDC: {
            incoming: { crypto: result.ethereum.USDC.incoming.crypto, usd: result.ethereum.USDC.incoming.usd, eur: result.ethereum.USDC.incoming.eur },
            outgoing: { crypto: result.ethereum.USDC.outgoing.crypto, usd: result.ethereum.USDC.outgoing.usd, eur: result.ethereum.USDC.outgoing.eur },
          },
          USDT: {
            incoming: { crypto: result.ethereum.USDT.incoming.crypto, usd: result.ethereum.USDT.incoming.usd, eur: result.ethereum.USDT.incoming.eur },
            outgoing: { crypto: result.ethereum.USDT.outgoing.crypto, usd: result.ethereum.USDT.outgoing.usd, eur: result.ethereum.USDT.outgoing.eur },
          }
        }
      }
    };
  };

  if(isLoading){
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  // console.log("specificBalance:", specificBalance);

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      {/* Left side - Invisible table */}
      <Box sx={{ width: '50%', padding: '20px' }}>
        <Grid container spacing={0.5}>
          {[...Array(6)].map((_, rowIndex) => (
            <Grid item xs={12} key={rowIndex}>
              <Grid container>
                {[...Array(5)].map((_, colIndex) => {
                  let columnWidth;
                  switch(colIndex) {
                    case 0:
                      columnWidth = 1.8;
                      break;
                    case 1:
                      columnWidth = 1.5;
                      break;
                    case 2:
                      columnWidth = 3.4;
                      break;
                    default:
                      columnWidth = 1.6;
                  }
                  return (
                  <Grid item 
                    xs={columnWidth}
                    key={colIndex} 
                    sx={{ height: '30px', border: '1px solid transparent' }}>
                    {rowIndex === 0 && (
                      <>
                        {colIndex === 0 && <Box sx={{ color: 'white', fontWeight: 'bold' }}>Status</Box>}
                        {colIndex === 1 && <Box sx={{ color: 'white', fontWeight: 'bold' }}>Direction</Box>}
                        {colIndex === 2 && <Box sx={{ color: 'white', fontWeight: 'bold' }}>
                          {selectedOption === 'Bitcoin' ? 'BTC' :
                           selectedOption === 'Ethereum' ? 
                              selectedToken === 'All Tokens' ? 'ETH' : selectedToken : 'Crypto'}
                        </Box>}
                        {colIndex === 3 && <Box sx={{ color: 'white', fontWeight: 'bold' }}>USD Value</Box>}
                        {colIndex === 4 && <Box sx={{ color: 'white', fontWeight: 'bold' }}>EUR Value</Box>}
                      </>
                    )}
                    {rowIndex === 1 && (
                      <>
                        {colIndex === 0 && <Box sx={{ color: 'white' }}>Confirmed</Box>}
                        {colIndex === 1 && <Box sx={{ color: 'white' }}>Credited</Box>}
                        {colIndex === 2 && <Box sx={{ color: 'white' }}>{specificBalance?.confirmed?.crypto?.toFixed() ?? '*'}</Box>}
                        {colIndex === 3 && <Box sx={{ color: 'white' }}>{specificBalance?.confirmed?.usd.toFixed(2) ?? '0'}</Box>}
                        {colIndex === 4 && <Box sx={{ color: 'white' }}>{specificBalance?.confirmed?.eur.toFixed(2) ?? '0'}</Box>}
                      </>
                    )}
                    {rowIndex === 2 && (
                      <>
                        {colIndex === 0 && <Box sx={{ color: 'white' }}></Box>}
                        {colIndex === 1 && <Box sx={{ color: 'white' }}></Box>}
                        {colIndex === 2 && <Box sx={{ color: 'white' }}></Box>}
                        {colIndex === 3 && <Box sx={{ color: 'white' }}></Box>}
                        {colIndex === 4 && <Box sx={{ color: 'white' }}></Box>}
                      </>
                    )}
                    {rowIndex === 3 && (
                      <>
                        {colIndex === 0 && <Box sx={{ color: 'white' }}>Unconfirmed</Box>}
                        {colIndex === 1 && <Box sx={{ color: 'white' }}>Incoming</Box>}
                        {colIndex === 2 && <Box sx={{ color: 'white' }}>{specificBalance?.incoming?.crypto?.toFixed() ?? '*'}</Box>}
                        {colIndex === 3 && <Box sx={{ color: 'white' }}>{specificBalance?.incoming?.usd.toFixed(2) ?? '0'}</Box>}
                        {colIndex === 4 && <Box sx={{ color: 'white' }}>{specificBalance?.incoming?.eur.toFixed(2) ?? '0'}</Box>}
                      </>
                    )}
                    {rowIndex === 4 && (
                      <>
                        {colIndex === 0 && <Box sx={{ color: 'white' }}></Box>}
                        {colIndex === 1 && <Box sx={{ color: 'white' }}>Outgoing</Box>}
                        {colIndex === 2 && <Box sx={{ color: 'white' }}>{specificBalance?.outgoing?.crypto?.toFixed() ?? '*'}</Box>}
                        {colIndex === 3 && <Box sx={{ color: 'white' }}>{specificBalance?.outgoing?.usd.toFixed(2) ?? '0'}</Box>}
                        {colIndex === 4 && <Box sx={{ color: 'white' }}>{specificBalance?.outgoing?.eur.toFixed(2) ?? '0'}</Box>}
                      </>
                    )}
                  </Grid>
                )})}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Right side - Space for future graph */}
      <Box sx={{ width: '50%', padding: '20px' }}>
        <BarChart
          width={500}
          height={300}
          data={[
            {
              name: 'Confirmed',
              crypto: specificBalance?.confirmed?.crypto?.toNumber() || 0,
              usd: specificBalance?.confirmed?.usd?.toNumber() || 0,
              eur: specificBalance?.confirmed?.eur?.toNumber() || 0
            },
            {
              name: 'Incoming',
              crypto: specificBalance?.incoming?.crypto?.toNumber() || 0,
              usd: specificBalance?.incoming?.usd?.toNumber() || 0,
              eur: specificBalance?.incoming?.eur?.toNumber() || 0
            },
            {
              name: 'Outgoing',
              crypto: specificBalance?.outgoing?.crypto?.toNumber() || 0,
              usd: specificBalance?.outgoing?.usd?.toNumber() || 0,
              eur: specificBalance?.outgoing?.eur?.toNumber() || 0
            }
          ]}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip />
          <Legend />
          <Bar dataKey="crypto" fill="#8884d8" yAxisId="left" />
          <Bar dataKey="usd" fill="#82ca9d" yAxisId="right" />
          <Bar dataKey="eur" fill="#ffc658" yAxisId="right" />
        </BarChart>
      </Box>
    </Box>
  );
};

export default NewBalance;