import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import logo from './logo.svg';
import LandingPage from "./LandingPage";
import "./LandingPage.css";
import LoginPage from "./LoginPage";
import "./LoginPage.css";
// import Dashboard from "./Dashboard";
import NewDashboard from "./New-Dashboard";
import AccountExplorer from "./AccountExplorer";
import ProtectedRoute from "./components/ProtectedRoute";
import Payment from "./Payment";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';


function ButtonAppBar() {
  const navigate = useNavigate();
  const location = useLocation();
  let loginLogout = undefined;
  switch(location.pathname) {
    case '/':
    case '/login':
      loginLogout = 'Login';
      break;
    default:
      if(location.pathname.startsWith('/payments/')) {
        loginLogout = 'Login';
      }
      else {
        loginLogout = 'Logout';
      }
      break;
  }

  function handleLoginLogout() {
    if(loginLogout === 'Login') {
      navigate('/login');
    }
    else {
      sessionStorage.removeItem('jwtToken');
      navigate('/');
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: 'gray' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {loginLogout === 'Logout' && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="/logo_no_background_tight_canvas4.png" alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
              <Button color="inherit" onClick={() => navigate('/dashboard')}>Dashboard</Button>
              <Button color="inherit" onClick={() => navigate('/explore')}>Explore</Button>
              {/* <Button color="inherit" onClick={() => navigate('/customers')}>Customers</Button> */}
            </div>
          )}
          {loginLogout === 'Login' && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="/logo_no_background_tight_canvas4.png" alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
              <Button color="inherit" onClick={handleLoginLogout}>{loginLogout}</Button>
            </div>
          )}
          {loginLogout === 'Logout' && (
            <div>
              <Button color="inherit" onClick={handleLoginLogout}>{loginLogout}</Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function App() {
  return (
    <Router>
      <ButtonAppBar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} /> */}
        <Route path="/dashboard" element={<ProtectedRoute><NewDashboard /></ProtectedRoute>} />
        <Route path="/explore" element={<ProtectedRoute><AccountExplorer /></ProtectedRoute>} />
        <Route path="/payments/:chain/:address/:amount" element={<Payment />} />
      </Routes>
    </Router>
  );
}

export default App;
