// LoginPage.js
import React from "react";
import LoginComponent from "./components/LoginComponent";

function LoginPage() {
  return (
    <div className="LoginPage">
      <header className="LoginPage-header">
        <h1>Login to Ascension Payment System</h1>
        <p>Please enter your login details:</p>
      </header>
      <LoginComponent />
    </div>
  );
}

export default LoginPage;
