import React from 'react';
import { Grid, Card, CardContent, Typography, Divider } from '@mui/material';
import BigNumber from 'bignumber.js';

const TransferDetails = ({selectedWallet, selectedTransfer }) => {
  console.log('selectedTransfer', selectedTransfer);
  console.log('selectedWallet', selectedWallet);

    const isOutgoing = selectedTransfer.direction === 'outgoing';
    const isBitcoin = selectedWallet.data.blockchain === 'bitcoin';
    const isEthereum = selectedWallet.data.blockchain === 'ethereum';

    let fullTransferDetails = undefined;
    if(isEthereum) {
      fullTransferDetails = selectedWallet.transactions[selectedTransfer.direction][selectedTransfer.currency].find(transfer => transfer.txid === selectedTransfer.transferId);
    }
    else if(isBitcoin){
      fullTransferDetails = selectedWallet.transactions[selectedTransfer.direction].find(transfer => transfer.txid === selectedTransfer.transferId);
    }
    console.log('fullTransferDetails', fullTransferDetails);

    const renderDetailCard = (title, value) => (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ height: '100%', backgroundColor: '#2c2c2c', color: 'white' }}>
                <CardContent>
                    <Typography variant="subtitle1" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2">
                        {value || 'N/A'}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );

    return (
        <Grid container spacing={2}>
            {renderDetailCard('Timestamp', selectedTransfer.timestamp)}
            {renderDetailCard('Direction', selectedTransfer.direction)}
            {isEthereum && renderDetailCard('Value', new BigNumber(selectedTransfer.value).toString(10))}
            {isBitcoin && renderDetailCard('Value', new BigNumber(selectedTransfer.value).toString(10))}
            {renderDetailCard('Currency', selectedTransfer.currency)}
            {isEthereum && renderDetailCard('Confirmed', fullTransferDetails.confirmed.toString())}
            {isBitcoin && renderDetailCard('Confirmations', fullTransferDetails.confirmations.toString())}
            {isBitcoin && renderDetailCard('Spent', fullTransferDetails.spent.toString())}
            <Grid item xs={12} sm={12} md={8} lg={6}>
                <Card sx={{ height: '100%', backgroundColor: '#2c2c2c', color: 'white' }}>
                    <CardContent>
                        <Typography variant="subtitle1" component="div">
                            Transfer ID
                        </Typography>
                        <Typography variant="body2">
                            {selectedTransfer.transferId || 'N/A'}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ backgroundColor: 'white', my: 2 }} />
            </Grid>

            {isOutgoing && (
              <>
                    {renderDetailCard('Requestor ID', fullTransferDetails.requestorId)}
                    {renderDetailCard('Payment Request ID', fullTransferDetails.paymentRequestId)}
                </>
            )}
            
            {renderDetailCard('Replaced By', fullTransferDetails.replacedBy)}

            {isOutgoing && (
              <>
                <Grid item xs={12}>
                    <Divider sx={{ backgroundColor: 'white', my: 2 }} />
                </Grid>
                {/* appear after the divider */}
                {renderDetailCard('Priority', fullTransferDetails.priority)}
              </>
            )}

            {isOutgoing && isEthereum && (
                <>
                    {renderDetailCard('Gas Price', new BigNumber(fullTransferDetails.gasPrice).toString(10))}
                    {renderDetailCard('Gas Used', new BigNumber(fullTransferDetails.gasUsed).toString(10))}
                    {renderDetailCard('Max Fee Per Gas', new BigNumber(fullTransferDetails.maxFeePerGas).toString(10))}
                    {renderDetailCard('Max Priority Fee Per Gas', new BigNumber(fullTransferDetails.maxPriorityFeePerGas).toString(10))}
                </>
            )}

            {isOutgoing && isBitcoin && (
                <>
                    {renderDetailCard('Fee', new BigNumber(fullTransferDetails.fee).toString(10))}
                </>
            )}
        </Grid>
    );
};

export default TransferDetails;
