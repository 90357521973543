/**
 * This class communicates with the backend server.
 */
import axios from "axios";
import settings from "../config/settings.json";

let APS_BACKEND_URL = undefined;
if (settings.production_mode) {
  APS_BACKEND_URL = settings.prod.aps_backend_url;
}
else {
  // in UAT we use self signed SSL certificates, so we need to tell Axios to accept them
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
  APS_BACKEND_URL = settings.uat.aps_backend_url;
}

const RequestMethodType = Object.freeze({
  GET: "GET",
  POST: "POST",
});

/**
 * Sends a request to the APS Backend
 * @param {RequestMethodType} method - whether this is a 'POST' or 'GET' request
 * @param {String} url - the URL the request should be sent to
 * @param {JSON} body - in case of a POST request, this object holds the data to be passed in the request body
 * @returns A promise for when this function completes. The results of the request will be passed on to the
 * response object.
 */
async function sendToApsBackend(method, url, body = undefined) {
  let config = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${sessionStorage.getItem("jwtToken")}`,
    },
    data: body,
    url: url,
  };

  if(!settings.production_mode) {
    config.rejectUnauthorized = false;
  }

  return axios(config)
    .then((res) => {
      if(res.data.haserror) {
        console.error("APS server returned error: ", res.data.error);
        throw new Error(res.data.error);
      }
    
      return res.data.data;
    });
}

class Backend {
  /**
   * Sends a request to the APS Backend to authenticate the user.
   * @param {JSON} body - the data to be sent in the request body
   * @returns A promise with the JWT token if the request is successful.
   */
  static async login(body) {
    const url = new URL("/get-jwt-token", APS_BACKEND_URL);
    return sendToApsBackend(RequestMethodType.POST, url.toString(), body)
      .then((res) => {
        return res.token;
      });
  }

  /**
   * Checks if the JWT token stored in the session storage is still valid.
   * @returns a promise with true if the token is valid or false otherwise.
   */
  static async validateToken() {
    const url = new URL("/validate-jwt-token", APS_BACKEND_URL);
    return sendToApsBackend(RequestMethodType.POST, url.toString())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error("Error occurred during token validation:", error);
        return false;
      })
  }

  /**
   * Gets the balance of all the accounts that the user has access to.
   * @returns a JSON object with the following structure:
   * {
   *  <customer key>: {
   *    <blockchain>: {
   *      <hd index>: {
   *         "total": {
   *            "balance": <total balance>
   *            "provChangeIn": <total provisional change in>,
   *            "provChangeOut": <total provisional change out>,
   *         }
   *         "breakdown": {
   *            <wallet address>: {
   *               "balance": <total balance>
   *               "provChangeIn": <total provisional change in>,
   *               "provChangeOut": <total provisional change out>,
   *            },
   *            ...
   *         }
   *        }
   *      }
   *   }
   * }
   */
  static async getBalance() {
    const url = new URL("/get-balance", APS_BACKEND_URL);
    const queryParams = new URLSearchParams({
      units: 'major'
    });
    url.search = queryParams.toString();

    return sendToApsBackend(RequestMethodType.GET, url.toString())
      .then((res) => {
        return res;
      });
  }

  static async getExchangeRate(from, to) {
    const url = new URL("/get-exchange-rate", APS_BACKEND_URL);
    const queryParams = new URLSearchParams({
      fromCurrency: from,
      toCurrency: to
    });
    url.search = queryParams.toString();

    return sendToApsBackend(RequestMethodType.GET, url.toString())
      .then((res) => {
        return res;
      });
  }
    

  /**
   * Gets the HD account information for a specific account.
   * @param {String} blockchain - the blockchain the wallet is on
   * @param {Integer} hdIndex - the HD index (Account key) we are interested in
   * @returns a JSON object with account information
   */
  static async getAccountInfo(blockchain, hdIndex) {
    const url = new URL("/get-account-info", APS_BACKEND_URL);

    const queryParams = new URLSearchParams({
      blockchain: blockchain,
      hdIndex: hdIndex
    });
    url.search = queryParams.toString();

    return sendToApsBackend(RequestMethodType.GET, url.toString())
      .then((res) => {
        return res;
      });
  }

  /**
   * Gets the wallet information for a specific wallet.
   * @param {String} blockchain - the blockchain the wallet is on
   * @param {String} hdIndex - the HD index (Account key) of the wallet
   * @param {String} walletAddress - (optional) the address of the wallet. If not provided will return info for all wallets
   * @param {Boolean} includeTransactions - whether the transaction history should be included in the result
   * @param {String} units - the units of the balance to be returned, can be 'major' or 'minor' (default is 'major')
   * @returns a JSON object with the following structure:
   */
  static async getWalletInfo(blockchain, hdIndex, walletAddress, includeTransactions, units) {
    const url = new URL("/get-wallet-info", APS_BACKEND_URL);

    const searchParams = {
      blockchain: blockchain,
      hdIndex: hdIndex,
      includeTransactions: includeTransactions ?? true,
      units: units ?? 'major'
    }
    if(walletAddress !== undefined){
      searchParams.walletAddress = walletAddress;
    }
    const queryParams = new URLSearchParams(searchParams);
    url.search = queryParams.toString();

    return sendToApsBackend(RequestMethodType.GET, url.toString())
      .then((res) => {
        return res;
      });
  }

  /**
   * Gets the transaction customers associated with a specific HD Account.
   * @param {String} blockchain - the blockchain the HD Account is on
   * @param {String} hdIndex - the HD index (Account key) of the account
   * @param {Boolean} sanitised - whether the data should be sanitised ot not
   * @returns an array containing the transaction customers in JSON format
   */
  static async getTransactionCustomers(blockchain, hdIndex, sanitised) {
    const url = new URL("/get-transaction-customers", APS_BACKEND_URL);

    const queryParams = new URLSearchParams({
      blockchain: blockchain,
      hdIndex: hdIndex,
      sanitised: sanitised
    });
    url.search = queryParams.toString();

    return sendToApsBackend(RequestMethodType.GET, url.toString())
      .then((res) => {
        return res;
      });
    }
}

export default Backend;
