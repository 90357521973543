import React from "react";

function LandingPage() {
  return (
    <div className="LandingPage">
      <header className="LandingPage-header">
        <h1>Ascension Payment System</h1>
        <p>Crypto payments made easy</p>
      </header>
    </div>
  );
}

export default LandingPage;
