import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Backend from '../external/backend';

const ProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Validate the token using the backend
        Backend.validateToken()
          .then((res) => {
            setIsAuthenticated(res);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error validating token:', error);
            setIsAuthenticated(false);
            setIsLoading(false);
          });
    }, []);

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;

