import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import './new-Dashboard.css';
import NewBalance from './components/New-Balance'; // Import the new component

function NewDashboard() {
  const [selectedOption, setSelectedOption] = useState('All Blockchains');
  const [selectedToken, setSelectedToken] = useState('All Tokens');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    if(event.target.value !== 'Ethereum'){
      setSelectedToken('All Tokens');
    }
  };

  const handleTokenChange = (event) => {
    setSelectedToken(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20px', height: '100vh' }}>
      <FormControl sx={{ minWidth: 200, marginBottom: '20px' }}>
        <InputLabel id="dashboard-dropdown-label" sx={{ color: 'white', top: '-8px' }}>Select Option</InputLabel>
        <Select
          labelId="dashboard-dropdown-label"
          id="dashboard-dropdown"
          value={selectedOption}
          label="Select Option"
          onChange={handleChange}
          sx={{
            backgroundColor: 'transparent',
            color: 'white',
            border: '1px solid white',
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
            '& .MuiInputLabel-root': { color: 'white', top: '-8px' }
          }}
        >
          <MenuItem value="All Blockchains">All Blockchains</MenuItem>
          <MenuItem value="Bitcoin">Bitcoin</MenuItem>
          <MenuItem value="Ethereum">Ethereum</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 200, marginBottom: '20px' }}>
        <InputLabel id="token-dropdown-label" sx={{ color: 'white', top: '-8px' }}>Select Token</InputLabel>
        <Select
          labelId="token-dropdown-label"
          id="token-dropdown"
          value={selectedOption === 'Ethereum' ? selectedToken : 'All Tokens'}
          label="Select Token"
          onChange={handleTokenChange}
          disabled={selectedOption !== 'Ethereum'}
          sx={{
            backgroundColor: 'transparent',
            color: 'white',
            border: '1px solid white',
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
            '& .MuiInputLabel-root': { color: 'white', top: '-8px' },
            '&.Mui-disabled': {
              color: 'rgba(255, 255, 255, 0.5)',
              '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.5)' }
            }
          }}
        >
          <MenuItem value="All Tokens">All Tokens</MenuItem>
          <MenuItem value="ETH">ETH</MenuItem>
          <MenuItem value="USDT">USDT</MenuItem>
          <MenuItem value="USDC">USDC</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ width: '100%', flexGrow: 1 }}>
        <NewBalance selectedOption={selectedOption} selectedToken={selectedToken} />
      </Box>
    </Box>
  );
}

export default NewDashboard;
